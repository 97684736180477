<template>
  <div>
    <!-- Header -->
    <header class="masthead mask-3" :style="banner">
      <div class="container">
        <div class="intro-text">
          <div class="intro-heading">{{ $t('openbmc.intro-heading') }}</div>
          <div class="intro-lead-in">{{ $t('openbmc.intro-lead-in') }}</div>
        </div>
      </div>
    </header>

    <!-- Content -->
    <section class="bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">{{ $t('openbmc.customize') }}</h2>
            <h3 class="section-subheading text-muted">Customization Feature Service</h3>
            <hr>
          </div>
        </div>

        <div class="row section-padding">
          <!-- Left column >>> -->
          <div class="col-md-6 text-center">

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>{{ $t('openbmc.OpenBMCArch') }}</h4>
                <p class="card_content">
                  <b>{{ $t('openbmc.OpenBMCArch1Title') }}</b>
                  {{ $t('openbmc.OpenBMCArch1Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.OpenBMCArch2Title') }}</b>
                  {{ $t('openbmc.OpenBMCArch2Content') }}
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>{{ $t('openbmc.maintenance') }}</h4>
                <p class="card_content">
                  <b>{{ $t('openbmc.maintenance1Title') }}</b>{{ $t('openbmc.maintenance1Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.maintenance2Title') }}</b>{{ $t('openbmc.maintenance2Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.maintenance3Title') }}</b>{{ $t('openbmc.maintenance3Content') }}
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>{{ $t('openbmc.management') }}</h4>
                <p class="card_content">
                  <b>{{ $t('openbmc.management1Title') }}</b>{{ $t('openbmc.management1Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.management2Title') }}</b>{{ $t('openbmc.management2Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.management3Title') }}</b>{{ $t('openbmc.management3Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.management4Title') }}</b>{{ $t('openbmc.management4Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.management5Title') }}</b>{{ $t('openbmc.management5Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.management6Title') }}</b>{{ $t('openbmc.management6Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.management7Title') }}</b>{{ $t('openbmc.management7Content') }}
                </p>
              </div>
            </div>

          </div>
          <!-- Left column <<< -->

          <!-- Right column >>> -->
          <div class="col-md-6 text-center">

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>{{ $t('openbmc.autoTest') }}</h4>
                <p class="card_content">
                  {{ $t('openbmc.autoTest1Content') }}
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>{{ $t('openbmc.control') }}</h4>
                <p class="card_content">
                  <b>{{ $t('openbmc.control1Title') }}</b>{{ $t('openbmc.control1Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.control2Title') }}</b>{{ $t('openbmc.control2Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.control3Title') }}</b>{{ $t('openbmc.control3Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.control4Title') }}</b>{{ $t('openbmc.control4Content') }}
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>{{ $t('openbmc.monitor') }}</h4>
                <p class="card_content">
                  <b>{{ $t('openbmc.monitor1Title') }}</b>{{ $t('openbmc.monitor1Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.monitor2Title') }}</b>{{ $t('openbmc.monitor2Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.monitor3Title') }}</b>{{ $t('openbmc.monitor3Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.monitor4Title') }}</b>{{ $t('openbmc.monitor4Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.monitor5Title') }}</b>{{ $t('openbmc.monitor5Content') }}
                </p>
                <p class="card_content">
                  <b>{{ $t('openbmc.monitor6Title') }}</b>{{ $t('openbmc.monitor6Content') }}
                </p>
              </div>
            </div>

          </div>
          <!-- Right column >>> -->
        </div>
      </div>
    </section>
    
    <section class="bg-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">{{ $t('openbmc.special') }}</h2>
            <h3 class="section-subheading text-muted">Product Advantage</h3>
            <hr>
          </div>
        </div>

        <div class="row section-padding">
          <!-- Left column >>> -->
          <div class="col-md-4 text-center my-auto">
            <!-- OpenBMC -->
            <div class="col-md-8 col-sm-5 mx-auto">
              <img class="img-fluid img-logo2" src="@/assets/img/logos/logo_openbmc.png">
            </div>
            <!-- Yocto -->
            <div class="col-md-8 col-sm-5 mx-auto">
              <img class="img-fluid img-logo2" src="@/assets/img/logos/logo_yocto.png">
            </div>
          </div>
          <!-- Left column <<< -->

          <!-- Right column >>> -->
          <div class="col-md-8">

            <div class="row text-left">
              <div class="col-md-10">
                <h4>{{ $t('openbmc.special1Title') }}</h4>
                <p class="card_content">
                  {{ $t('openbmc.special1Content') }}
                </p>
                <br>
                <h4>{{ $t('openbmc.special2Title') }}</h4>
                <p class="card_content">
                  {{ $t('openbmc.special2Content') }}
                </p>
                <br>
                <h4>{{ $t('openbmc.special3Title') }}</h4>
                <p class="card_content">
                  {{ $t('openbmc.special3Content') }}
                </p>
                <br>
                <h4>{{ $t('openbmc.special4Title') }}</h4>
                <p class="card_content">
                  {{ $t('openbmc.special4Content') }}
                </p>
              </div>
            </div>

          </div>
          <!-- Right column >>> -->
        </div>
      </div>
    </section>
    
    <div class="row bg-gray">
      <div class="col-lg-12 text-center">
        <font color="gray" size="2px">{{ $t('openbmc.claim') }}</font>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: {
        backgroundImage: `url(${require('@/assets/img/banner/type2.jpg')})`
      }
    }
  }
}
</script>