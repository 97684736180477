import Vue from 'vue'
import Router from 'vue-router'
import i18n from './i18n'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: `/${i18n.locale}`
    },
    {
      path: '/:lang',
      component: {
        render (c) {
          // Change title by language.
          if(i18n.locale === 'ja-jp') {
            document.title = "Action-Lead Technology Co., Ltd.";
          }
          else {
            document.title = "艾樂資訊股份有限公司";
          }

          return c('router-view');
        }
      },
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('./views/Home.vue')
        },
        {
          path: 'beginning',
          name: 'beginning',
          component: () => import('./views/Beginning.vue')
        },
        {
          path: 'team',
          name: 'team',
          component: () => import('./views/Team.vue')
        },
        {
          path: 'openbmc',
          name: 'openbmc',
          component: () => import('./views/Openbmc.vue')
        },
        // {
        //   path: 'pos',
        //   name: 'pos',
        //   component: () => import('./views/Pos.vue')
        // },
        // {
        //   path: 'success',
        //   name: 'success',
        //   component: () => import('./views/Success.vue')
        // },
        {
          path: 'contact',
          name: 'contact',
          component: () => import('./views/Contact.vue')
        }
      ]
    },
    {
      path: '/*',
      redirect: `/${i18n.locale}`
    }
  ],
  scrollBehavior() {
    return {
      x: 0, y: 0
    }
  }
})

// Fix "NavigationDuplicated" bug. >>>
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// Fix "NavigationDuplicated" bug. <<<
