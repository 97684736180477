<template>
  <div>
    <!-- Header -->
    <header class="masthead mask-5" :style="banner">
      <div class="container">
        <div class="intro-text">
          <div class="intro-heading">{{ $t('contact.intro-heading') }}</div>
          <div class="intro-lead-in">{{ $t('contact.intro-lead-in') }}</div>
        </div>
      </div>
    </header>

    <!-- Content -->
    <section class="bg-white">
      <div class="container">

        <div class="row section-padding">
          <!-- Left column >>> -->
          <div class="col-md-7 text-center">
            <br>

            <div class="row">
              <div class="col-lg-12 text-center">
                <h2 class="section-heading"></h2>
                <h3 class="section-subheading text-muted"></h3>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-6">
                <div class="col-md-2 col-sm-3">
                  <img class="img-fluid img-little-icon" src="@/assets/img/icon/map.png">
                </div>
                <div class="col-md-12">
                  <h4>{{ $t('contact.addrTitle') }}</h4>
                  <p class="card_content">
                    {{ $t('contact.addrContent') }}
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="col-md-2 col-sm-3">
                  <img class="img-fluid img-little-icon" src="@/assets/img/icon/clock.png">
                </div>
                <div class="col-md-12">
                  <h4>{{ $t('contact.time') }}</h4>
                  <p class="card_content">
                    Mon. to Fri.<br>
                    9:00 am – 6:00 pm
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="col-md-2 col-sm-3">
                  <img class="img-fluid img-little-icon" src="@/assets/img/icon/map.png">
                </div>
                <div class="col-md-12">
                  <h4>E-MAIL</h4>
                  <p class="card_content">
                    <a href="mailto:contact@action-lead.com">contact@action-lead.com</a>
                  </p>
                </div>
              </div>

              <div class="col-md-6" v-if="false">
                <div class="col-md-2 col-sm-3">
                  <img class="img-fluid img-little-icon" src="@/assets/img/icon/map.png">
                </div>
                <div class="col-md-12">
                  <h4>{{ $t('contact.tel') }}</h4>
                  <p class="card_content">
                    <a href="tel:+886-2-87513311">+886-2-87513311 ext.132</a>
                  </p>
                </div>
              </div>
            </div>

          </div>
          <!-- Left column <<< -->

          <!-- Right column >>> -->
          <div class="col-md-5 text-center">
            <br>
            <div class="row">
              <div class="col-lg-12 text-center">
                <h2 class="section-heading">{{ $t('contact.question') }}</h2>
                <h3 class="section-subheading text-muted">Leave your question</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 text-left">
                <!-- David -->
                <!-- <form id="my-form" action="https://formspree.io/f/mpzklvqb" method="POST" onSubmit="return false"> -->

                <!-- Ethan -->
                <form id="my-form" action="https://formspree.io/f/mgerlwpo" method="POST" onSubmit="return false">
                  <div class="form-group">
                    <label>{{ $t('contact.company') }}<font color="red">*</font></label>
                    <input class="form-control" type="text" name="company" required/>
                    <br>
                    <label>{{ $t('contact.name1') }}</label>
                    <input class="form-control" type="text" name="name1" />
                    <br>
                    <label>{{ $t('contact.name2') }}<font color="red">*</font></label>
                    <input class="form-control" type="text" name="name2" required/>
                    <br>
                    <label>{{ $t('contact.contactTel') }}</label>
                    <input class="form-control" type="phone" name="phone" />
                    <br>
                    <label>
                      <font v-if="this.$route.path.indexOf('zh-cn') !== -1 || this.$route.path.indexOf('zh-tw') !== -1">
                        公司
                      </font>
                      E-mail
                      <font color="red">*</font>
                    </label>
                    <br>
                    <input class="form-control" type="email" name="email" required/>
                    <br>
                    <label>{{ $t('contact.message') }}<font color="red">*</font></label>
                    <textarea class="form-control" name="message" id="" rows="4" required></textarea>
                    <br>
                    <label>{{ $t('contact.code1') }}<font color="red">*</font> <img id="imgcode" src="/imgcode.php" v-on:click="refresh_code" /> {{ $t('contact.code2') }}</label>
                    <p><input type="text" name="vcode" value="" :placeholder="$t('contact.codeType')" class="form-control form-control-user reg_vcode" id="reg_vcode" required><span class="vcode_hint"></span></p>

                    <p class="p-1 text-muted" style="font-size: 17px;">
                      {{ $t('contact.safeNotice') }}
                    </p>

                  </div>
                  <button class="btn btn-primary" id="my-form-button">{{ $t('contact.submit') }}</button>

                  <!-- Feedback Message -->
                  <p id="my-form-status"></p>

                </form>
              </div>
            </div>

          </div>
          <!-- Right column >>> -->
        </div>
      </div>
    </section>

    <section class="bg-gray" >
      <div class="container">

        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">{{ $t('contact.location') }}</h2>
            <h3 class="section-subheading text-muted">Our Location</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-9 mx-auto">
            <iframe v-if="this.$route.path.indexOf('zh-tw')!==-1 || this.$route.path.indexOf('ja-jp')!==-1"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.488472081969!2d121.53081001118669!3d25.051427937496566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a9611028aab3%3A0x9c313603ad48987d!2zMTA0OTHlj7DljJfluILkuK3lsbHljYDmnb7msZ_ot68xMTHomZ8!5e0!3m2!1szh-TW!2stw!4v1692172457721!5m2!1szh-TW!2stw"
              width="100%"
              height="500"
              style="border:0;"
              allowfullscreen=""
              loading="lazy">
            </iframe>
            <iframe v-if="this.$route.path.indexOf('zh-cn')!==-1"
              src="https://map.baidu.com/poi/BOSS%20TOWER/@13530331.02,2864244.77,19z?uid=55d66fb865df78a85b109391&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl"
              width="100%"
              height="500"
              frameborder="0"
              scrolling="no">
            </iframe>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
import jq from 'jquery'

export default {
  data() {
    return {
      banner: {
        backgroundImage: `url(${require('@/assets/img/banner/contact.jpg')})`
      }
    }
  },
  mounted(){
    var that = this;
    var vcode_Boolean = false;

    //偵測輸入
    jq('.reg_vcode').blur(function(){
      //判定輸入值
      if ((/^[a-zA-Z0-9_-]{4,4}$/).test(jq(".reg_vcode").val())){
        var acode = document.getElementById("reg_vcode").value;
        jq.ajax({
          type: "POST",
          url: "/ajax.php",
          data: "data="+acode+"&action=chkvcode",
          cache: false,
          success: function(data){
            //回傳資料
            if(data == 'OK'){
              jq('.vcode_hint').html(that.$t('contact.correct')).css("color","green");
              jq('.reg_vcode').css("border", "1px solid green");
              vcode_Boolean = true;
            }else{
              jq('.vcode_hint').html(that.$t('contact.wrong')).css("color","red");
              jq('.reg_vcode').css("border", "1px solid red");
              vcode_Boolean = false;
            }
          }
        });
      }else {
        jq('.vcode_hint').html(that.$t('contact.wrong')).css("color","red");
        jq('.reg_vcode').css("border", "1px solid red");
        vcode_Boolean = false;
      }
    });
    // 驗證碼 <<<

    var form = document.getElementById("my-form");

    async function handleSubmit(event) {
      // 先判斷驗證碼
      if(vcode_Boolean != true){
        alert(that.$t('contact.response1'));
      }
      else{
        event.preventDefault();
        var data = new FormData(event.target);
        fetch(event.target.action, {
          method: form.method,
          body: data,
          headers: {
            'Accept': 'application/json'
          }
        }).then(() => {
          alert(that.$t('contact.response2'));
          form.reset()
        }).catch(() => {
          alert(that.$t('contact.response3'));
        });
      }
    }
    form.addEventListener("submit", handleSubmit)
  },
  methods:{
    refresh_code() {
      document.getElementById("imgcode").src="/imgcode.php";
    }
  }
}
</script>