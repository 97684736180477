<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
    <div class="container">
      <!-- Logo -->
      <router-link
        class="navbar-brand js-scroll-trigger"
        :to="`/${$i18n.locale}`"
      >
        <img class="mainLogo" src="@/assets/img/logos/logo1.png" />
      </router-link>

      <!-- Responsive Phone Navbar -->
      <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-toggle="collapse"
        data-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-label="Toggle navigation"
      >
        {{ $t('nav.menu') }}
        <i class="fas fa-bars"></i>
      </button>

      <!-- Responsive PC Navbar -->
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <div class="dropdown">
            <button class="btn nav-btn" type="button">
              <router-link
                :to="`/${$i18n.locale}`"
                style="text-decoration:none;"
              >
                {{ $t('nav.home') }}
              </router-link>
            </button>
          </div>

          <div class="dropdown">
            <button
              class="btn dropdown-toggle nav-btn"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t('nav.aboutus') }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <router-link
                class="dropdown-item"
                :to="`/${$i18n.locale}/beginning`"
                >{{ $t('nav.beginning') }}</router-link
              >
              <router-link
                class="dropdown-item"
                :to="`/${$i18n.locale}/team`"
                >{{ $t('nav.team') }}</router-link
              >
              <!--<router-link class="dropdown-item" :to="`/${$i18n.locale}/success`">{{ $t('nav.success') }}</router-link>-->
            </div>
          </div>

          <div class="dropdown">
            <button
              class="btn dropdown-toggle nav-btn"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t('nav.product') }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <router-link
                class="dropdown-item"
                :to="`/${$i18n.locale}/openbmc`"
                >{{ $t('nav.openbmc') }}</router-link
              >
              <!--<router-link
                class="dropdown-item"
                :to="`/${$i18n.locale}/pos`"
                v-if="this.$route.path.indexOf('zh-tw') !== -1"
                >{{ $t('nav.pos') }}</router-link
              >-->
            </div>
          </div>

          <div class="dropdown">
            <button class="btn nav-btn" type="button">
              <router-link
                :to="`/${$i18n.locale}/contact`"
                style="text-decoration:none;"
              >
                {{ $t('nav.contact') }}
              </router-link>
            </button>
          </div>

          <!-- Change Language -->
          <div class="dropdown my-auto changeLan">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style="border: solid 1px"
            >
              {{ $t('nav.changeLangauge') }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="setLocale('zh-tw')"
                >繁體中文</a
              >
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="setLocale('zh-cn')"
                >简体中文</a
              >
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="setLocale('ja-jp')"
                >日本語</a
              >
            </div>
          </div>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import jq from 'jquery';
// import axios from 'axios';

export default {
  mixins: [clickaway],
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
    };
  },
  async created() {
    // let that = this;

    // Fetch client IP
    // async function getIpClient() {
    //   try {
    //     let response = await axios.get('https://ipinfo.io/json');

    //     // Record client IP to mysql database
    //     axios.post('/recordIp.php', {
    //       ip: response.data.ip,
    //       hostname: response.data.hostname,
    //       city: response.data.city,
    //       region: response.data.region,
    //       loc: response.data.loc,
    //       timeStr: that.getTime(),
    //     });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    // await getIpClient();
  },
  methods: {
    showMenu() {
      this.isVisible = true;
    },
    hideMenu() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    hideDropdown() {
      jq('#navbarResponsive').removeClass('show');
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push({
        params: { lang: locale },
      });
      this.hideDropdown();
    },
    getTime() {
      // Time format: "yyyy/mm/dd-hh:mm:ss"
      // For example:  2022/12/22-20:34:22
      let date = new Date();

      let YY = date.getFullYear();
      let MM =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      let mm =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      let ss =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      let timeStr = YY + '/' + MM + '/' + DD + '-' + hh + ':' + mm + ':' + ss;

      return timeStr;
    },
  },
};
</script>
