<template>
  <div>
    <!-- Header -->
    <header class="masthead mask-1" :style="banner">
      <div class="container">
        <div class="intro-text">
          <div class="intro-heading">{{ $t('beginning.intro-heading') }}</div>
          <div class="intro-lead-in">{{ $t('beginning.intro-lead-in') }}</div>
        </div>
      </div>
    </header>

    <!-- Content -->
    <section class="bg-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 mx-auto">
            <img class="img-fluid img-logo" src="@/assets/img/logos/logo_white1.png">
          </div>
        </div>

        <div class="row section-padding">
          <p class="content">
            {{ $t('beginning.content1') }}
            <br><br>
            {{ $t('beginning.content2') }}
            <br><br>
            {{ $t('beginning.content3') }}
          </p>
        </div>

        <div class="row">
          <div class="col-lg-12 text-center">
            <router-link class="btn btn-primary" :to="`/${$i18n.locale}/team`">
              {{ $t('beginning.ourTeam') }}
            </router-link>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: {
        backgroundImage: `url(${require('@/assets/img/banner/begin1.jpg')})`
      }
    }
  }
}
</script>