<template>
  <div>
    <!-- Header -->
    <header class="masthead" :style="banner">
      <div class="container">
        <div class="intro-text">
          <div
            :class="{
              'intro-heading': !isEnglishTitle,
              'englishTitle': isEnglishTitle
            }"
          >
            {{ $t('home.intro-heading') }}
          </div>
          <div class="intro-lead-in">{{ $t('home.intro-lead-in') }}</div>
          <a class="btn btn-primary js-scroll-trigger" href="#aboutus">
            {{ $t('home.know-more') }}
          </a>
        </div>
      </div>
    </header>

    <!-- Content -->
    <section class="bg-white" id="aboutus">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">{{ $t('home.BMCSolution') }}</h2>
            <h3 class="section-subheading text-muted">BMC Solution</h3>
            <hr>
          </div>
        </div>

        <div class="row section-padding">
          <div class="col-md-4 col-sm-6">
            <img class="img-fluid img-icon" src="@/assets/img/icon/bag1.png">
            <div class="portfolio-caption">
              <h3 class="p-2 section-heading text-center">{{ $t('home.solutionTitle1') }}</h3>
              <p class="content">{{ $t('home.solutionContent1') }}</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <img class="img-fluid mx-auto img-icon" src="@/assets/img/icon/chat.png">
            <div class="portfolio-caption">
              <h3 class="p-2 section-heading text-center">{{ $t('home.solutionTitle2') }}</h3>
              <p class="content">{{ $t('home.solutionContent2') }}</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <img class="img-fluid img-icon" src="@/assets/img/icon/coding1.png">
            <div class="portfolio-caption">
              <h3 class="p-2 section-heading text-center">{{ $t('home.solutionTitle3') }}</h3>
              <p class="content">{{ $t('home.solutionContent3') }}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 text-center">
            <a class="js-scroll-trigger" href="#adventage">
              <i class="fas fa-chevron-circle-down" style="font-size:50px"></i>
            </a>
          </div>
        </div>
      </div>
    </section>

    <!-- About -->
    <section id="adventage" class="bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">{{ $t('home.ourAdvantage') }}</h2>
            <h3 class="section-subheading text-muted">Our Advantage</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <ul class="timeline">

              <li class="timeline-inverted">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" src="@/assets/img/icon/businessman.png">
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4 class="subheading">{{ $t('home.advantageTitle1') }}</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted">
                      {{ $t('home.solutionContent1') }}
                    </p>
                  </div>
                </div>
              </li>

              <li class="timeline-inverted">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" src="@/assets/img/icon/coding2.png">
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4 class="subheading text-left">{{ $t('home.advantageTitle2') }}</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted text-left">
                      {{ $t('home.solutionContent2') }}
                    </p>
                  </div>
                </div>
              </li>

              <li class="timeline-inverted">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" src="@/assets/img/icon/fast.png">
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4 class="subheading">{{ $t('home.advantageTitle3') }}</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted">
                      {{ $t('home.solutionContent3') }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="row button-padding-top">
          <div class="col-lg-12 text-center">
            <router-link class="btn btn-primary" :to="`/${$i18n.locale}/openbmc`">
              {{ $t('home.customization') }}
            </router-link>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
import $ from 'jquery'

export default {
  data() {
    return {
      banner: {
        backgroundImage: `url(${require('@/assets/img/banner/server.jpg')})`
      }
    }
  },
  computed: {
    isEnglishTitle() {
      if(this.$i18n.locale === 'ja-jp') {
        return true;
      }
      return false;
    }
  },
  mounted() {
    // Smooth scrolling using jQuery easing when press href. >>>
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: (target.offset().top - 54)
          }, 500, "swing");
          return false;
        }
      }
    });
    // Smooth scrolling using jQuery easing when press href. <<<
  }
}
</script>

<style lang="css">
.englishTitle {
  font-size: 31px;
  letter-spacing: 2px;
}
@media (min-width: 768px) {
  .englishTitle {
    font-size: 40px;
    letter-spacing: 3px;
  }
}
@media (min-width: 992px) {
  .englishTitle {
    font-size: 45px;
    letter-spacing: 3px;
  }
}
</style>