<template>
  <div>
    <!-- Header -->
    <header class="masthead mask-1" :style="banner">
      <div class="container">
        <div class="intro-text">
          <div class="intro-heading">{{ $t('team.intro-heading') }}</div>
          <div class="intro-lead-in">{{ $t('team.intro-lead-in') }}</div>
        </div>
      </div>
    </header>

    <!-- Content -->
    <section class="bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">{{ $t('team.teamExperience') }}</h2>
            <h3 class="section-subheading text-muted">Team Experience</h3>
            <hr>
          </div>
        </div>

        <div class="row section-padding">
          <p class="content">{{ $t('team.teamExperienceContent1') }}</p>
          <p class="content">{{ $t('team.teamExperienceContent2') }}</p>
        </div>
      </div>
    </section>

    <section class="bg-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">{{ $t('team.platformExperience') }}</h2>
            <h3 class="section-subheading text-muted">Platform Development Experience</h3>
            <hr>
          </div>
        </div>

        <div class="row section-padding">
          <!-- Left column >>> -->
          <div class="col-md-6 text-center">
            <h3 class="section-heading-card">{{ $t('team.CPUPlatform') }}</h3>
            <h4 class="section-subheading-card">CPU Platform</h4>

            <div class="row single_card text-left pb-3">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>Intel</h4>
                <p class="card_content">
                  Romley / Grantley / Purley / Whitley / Eagle Stream<br>
                  Denlow / Greenlow / Mehlow<br>
                  Avoton / Denverton NS
                </p>
              </div>
            </div>

            <div class="row single_card text-left pt-0">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>AMD</h4>
                <p class="card_content">
                  AM4 / Naples / Rome / Milan
                </p>
              </div>
            </div>

            <div class="row single_card text-left pt-2">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>{{ $t('team.phytium') }}</h4>
                <p class="card_content">
                  S2500
                </p>
              </div>
            </div>

            <div class="row single_card text-left pt-2">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>{{ $t('team.hygon') }}</h4>
                <p class="card_content">
                  {{ $t('team.hygon-3') }}
                </p>
              </div>
            </div>

            <div class="row single_card text-left pt-2">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>{{ $t('team.zhaoxin') }}</h4>
                <p class="card_content">
                  KH-40000 16C
                </p>
              </div>
            </div>

          </div>
          <!-- Left column <<< -->

          <!-- Right column >>> -->
          <div class="col-md-6 text-center">
            <h3 class="section-heading-card">{{ $t('team.serverExperience') }}</h3>
            <h4 class="section-subheading-card">Server Management Chip</h4>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>ASPEED</h4>
                <p class="card_content">
                  1010 / 2300 / 2400 / 2500 / 2510 / 2520 / 2600
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>Nuvoton</h4>
                <p class="card_content">
                  WPCM450
                </p>
              </div>
            </div>

          </div>
          <!-- Right column >>> -->
        </div>
      </div>
    </section>

    <section class="bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">{{ $t('team.BMCFirmwareExperience') }}</h2>
            <h3 class="section-subheading text-muted">BMC Firmware Development Experience</h3>
            <hr>
          </div>
        </div>

        <div class="row section-padding">
          <!-- Left column >>> -->
          <div class="col-md-6 text-center">

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>AMI</h4>
                <p class="card_content">
                  MegaRAC SP2, SP-X RR4, RR7, RR10, RR11, RR12 LTS
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>{{ $t('team.CETC') }}</h4>
                <p class="card_content">
                  {{ $t('team.Kuanlaun') }}
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>Avocent</h4>
                <p class="card_content">
                  MergePoint EMS
                </p>
              </div>
            </div>

          </div>
          <!-- Left column <<< -->

          <!-- Right column >>> -->
          <div class="col-md-6 text-center">

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>Linux Foundation Project</h4>
                <p class="card_content">
                  OpenBMC
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>Insyde</h4>
                <p class="card_content">
                  Supervyse3.5, OPF OpenBMC
                </p>
              </div>
            </div>

          </div>
          <!-- Right column >>> -->
        </div>
      </div>
    </section>

    <section class="bg-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">{{ $t('team.projectExperience') }}</h2>
            <h3 class="section-subheading text-muted">Project Experience</h3>
            <hr>
          </div>
        </div>

        <div class="row section-padding">
          <div class="col-md-8 text-center mx-auto">

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>DELL</h4>
                <p class="card_content">
                  PowerEdge T, R series<br>
                  DCS ODM project
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>Lenovo</h4>
                <p class="card_content">
                  ODCC scorpio rack system ODC5000, ODC5100, ODC5200<br>
                  GP(RS160/TS460/MLK)<br>
                  Hyper Scale(SD600/RD850)<br>
                  C2C(Hulk) ODM project
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>CMCC</h4>
                <p class="card_content">
                  {{ $t('team.CMCC') }}<br>
                  OTII (Open Telecom IT Infrastructure) SE550
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>Tencent</h4>
                <p class="card_content">
                  {{ $t('team.tencent') }}
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>Canbricon</h4>
                <p class="card_content">
                  AI computer management system
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>Inventec</h4>
                <p class="card_content">
                  Container rack system
                </p>
              </div>
            </div>

            <div class="row single_card text-left">
              <div class="col-md-2 col-sm-3 mx-auto">
                <img class="img-fluid img-icon2" src="@/assets/img/icon/monitor.png">
              </div>
              <div class="col-md-10">
                <h4>Baidu</h4>
                <p class="card_content">
                  {{ $t('team.server') }}
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: {
        backgroundImage: `url(${require('@/assets/img/banner/team.png')})`
      }
    }
  }
}
</script>