<template>
  <div id="app">

    <!-- Navigation -->
    <mega-menu />

    <!-- Content -->
    <router-view/>

    <!-- Scroll to top icon -->
    <a href="javascript:" id="return-to-top">
      <i class="fas fa-chevron-up"></i>
    </a>

    <!-- Contact US -->
    <section class="contactus">
      <div class="container">
        <div class="text-center">
          <div class="col-lg-12 mr-auto text-center">
            <router-link :to="`/${$i18n.locale}/contact`" style="text-decoration:none;">
              <img class="img-fluid" src="@/assets/img/icon/mail2.svg">
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mx-auto text-center">
            <h3 class="section-heading">
              <router-link :to="`/${$i18n.locale}/contact`" style="text-decoration:none;">
                {{ $t('footer.contactus') }}
              </router-link>
            </h3>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <span class="copyright">{{ $t('footer.copyright') }}</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MegaMenu from '@/components/MegaMenu.vue'
import $ from 'jquery';

export default {
  name: 'app',
  components: {
    MegaMenu,
  },
  mounted() {
    // Scroll to Top >>>>>>>
    $(window).scroll(function() {
      if ($(this).scrollTop() >= 500) {
        // If page is scrolled more than 500 px
        $('#return-to-top').fadeIn(100); // Fade in the arrow
      } else {
        $('#return-to-top').fadeOut(100); // Else fade out the arrow
      }
    });
    $('#return-to-top').click(function() {
      // When arrow is clicked
      $('body,html').animate(
        {
          scrollTop: 0, // Scroll to top of body
        },
        800
      );
    });
    // Scroll to Top <<<<<<<
  },
}
</script>

<style src="@/assets/css/agency.css" \>
